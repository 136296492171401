import { ArrowRightOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Drawer, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { isEmpty, map, toLower } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Image from "../../../components/general/components/Image";
import { not_foundIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getHighFrequencyRobots from "../../../newApi/robots/getHighFrequencyRobots";
import { triggerModal } from "../../../redux/actions/app-actions";
import { updateDepositActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { getCoinTypeIcon } from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup
const PAGE_SIZE = 10;
const maxHeight = "420px";
const linkedPercentageSelections = [15, 20, 25, 30, 35, 40, 45, 50];

const AutoLinkRobotPage = (props) => {
  // const accessKey = get(props.user, "accessKey");
  const { t } = useTranslation();
  // const location = useLocation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [robots, setRobots] = useState([]);
  const [othersLinks, setOthersLinks] = useState([]);
  // const [linkedByRobots, setLinkedByRobots] = useState([]);
  // const [linkedRobots, setLinkedRobots] = useState([]);
  // const [confirmVisible, setConfirmVisible] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  // const [transactions, setTransactions] = useState([]);
  const [visible, setVisible] = useState(false);

  const profile = props.profile;

  message.config({
    duration: 3,
  });
  // let product = get(props, "data");

  useEffect(() => {
    if (!isEmpty(profile) && props.edit === 1) {
      //   console.log("here", props);
      let robotProfiles = get(profile, "robotProfiles");
      robotProfiles = map(robotProfiles, (item, index) => {
        return get(item, "robotProfileId");
      });
      getHighFrequencyRobots({
        profileIdIn: robotProfiles,
        liquidation: 0,
        profitTypeNe: 1,
        limit: "all",
        isAdmin: true,
        exchange: props?.platform || "binance",
        needLinkByOthers: 1,
      })
        .then((res) => {
          //   console.log("need others res", res);
          let data = res?.data || [];
          setOthersLinks(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [props.profile, props.edit]);

  useEffect(() => {
    setVisible(props.visible === true);
    if (props.visible === true) {
      getData();
    }
  }, [props.visible]);

  function getData() {
    let robotProfiles = get(profile, "robotProfiles");
    if (!isEmpty(robotProfiles)) {
      robotProfiles = map(robotProfiles, (item, index) => {
        return get(item, "robotProfileId");
      });
      // console.log(props?.platform, " plarform");
      setLoading(true);
      getHighFrequencyRobots({
        profileIdIn: robotProfiles,
        liquidation: 0,
        profitTypeNe: 1,
        limit: "all",
        isAdmin: true,
        exchange: props?.platform || "binance",
        enableLinkRobot: 0,
        // needLinkByOthersNe: 1,
      })
        .then((res) => {
          //   console.log("res", res);
          let data = res?.data || [];
          setRobots(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  function close() {
    if (props.onClose) {
      //   form.resetFields();
      props.onClose();
      setVisible(false);
    } else {
      setVisible(false);
    }
  }

  function getRobotDisplayText(robot) {
    // let convertedSymbol = get(robot, "symbol")?.replace("USDT", "");
    let convertedSymbol = get(robot, "symbol");
    return `${convertedSymbol} - ${get(robot, "_id")?.substring(
      get(robot, "_id").length - 4
    )} ${get(robot, "remark") ? `(${robot.remark})` : ""}`;
  }

  return (
    <React.Fragment>
      <Drawer
        visible={visible}
        width="100%"
        className="body-no-padding"
        closable={false}
        onClose={() => {
          close();
        }}
      >
        <Layout>
          <Spin spinning={loading}>
            <NavHeader
              suffix={
                <span className="inline-block text-base text-right font-semibold">
                  {/* 机器人记录{" "}
                  <MediaQuery maxWidth={440}>
                    <br />
                  </MediaQuery> */}
                  {t("autoLink", sourceKey.highFrequency)}
                </span>
              }
              showBack={true}
              onBack={() => {
                close();
              }}
            >
              <div className="p-4 " style={{ minHeight: "140px" }}>
                {!isEmpty(robots) ? (
                  <>
                    <div className="grid grid-cols-5 gap-2 mt-2">
                      <div
                        className="col-span-2 border-2 p-2"
                        style={{
                          maxHeight: "500px",
                          overflowY: "scroll",
                        }}
                      >
                        {map(robots, (item, index) => {
                          //   let selectedRobot = find(robots, (item2, index) => {
                          //     return item2?._id === item;
                          //   });
                          let convertedSymbol = get(item, "symbol")?.replace(
                            "USDT",
                            ""
                          );
                          return (
                            <div className="flex justify-start py-1">
                              <span className="pr-1">
                                <Image
                                  src={getCoinTypeIcon(
                                    toLower(convertedSymbol)
                                  )}
                                  style={{ width: 20, height: 20 }}
                                  className=" pl-0.5"
                                />
                              </span>
                              <span className="text-xs">
                                {convertedSymbol} -{" "}
                                {get(item, "_id")?.substring(
                                  get(item, "_id").length - 4
                                )}{" "}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-span-1 flex justify-center items-center">
                        <DollarCircleOutlined /> <ArrowRightOutlined />
                      </div>
                      <div
                        className="col-span-2 border-2 p-2"
                        style={{
                          maxHeight: "500px",
                          overflowY: "scroll",
                        }}
                      >
                        {map(othersLinks, (item, index) => {
                          let convertedSymbol = get(item, "symbol")?.replace(
                            "USDT",
                            ""
                          );
                          return (
                            <div className="flex justify-start py-1">
                              <span className="pr-1">
                                <Image
                                  src={getCoinTypeIcon(
                                    toLower(convertedSymbol)
                                  )}
                                  style={{ width: 20, height: 20 }}
                                  className=" pl-0.5"
                                />
                              </span>
                              <span className="text-xs">
                                {convertedSymbol} -{" "}
                                {get(item, "_id")?.substring(
                                  get(item, "_id").length - 4
                                )}{" "}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="mt-7">
                    <img
                      src={not_foundIcon}
                      className=" object-contain h-full w-full"
                      style={{ height: "10vh" }}
                    />

                    <br></br>
                    <div className="flex justify-center items-center">
                      <div
                        className="flex justify-center items-center font-black text-l text-center mb-2 text-gray-400"
                        style={{ width: 310 }}
                      >
                        {t("noRecords", sourceKey.highFrequency)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </NavHeader>
            {/* <ConfirmationModal //delete confirm modal
              visible={confirmVisible}
              // closableOnMask={false}
              onClose={() => {
                setConfirmVisible(false);
              }}
              confirmButton={(handleOk) => {
                return (
                  <div className="mt-10">
                    <Button
                      block
                      className="main-button-color input-border"
                      size="large"
                      loading={loading}
                      onClick={(e) => {
                        // ConfirmAgreement();
                        onSubmit({});
                        // setConfirmVisible(false);
                      }}
                    >
                      {t("confirm")}
                    </Button>
                  </div>
                );
              }}
            >
              <div className="text-base font-semibold pb-4">
                {t("confirmMultiLink", sourceKey.highFrequency)}
              </div>
              <div className=" pb-2">
                <div className="flex ">
                  <span className="inline-block w-1/3">
                    {t("sharePercentage", sourceKey.highFrequency)}
                  </span>
                  <span className="mx-2">:</span>
                  <span>{linkedPercentage || 0} %</span>
                </div>
              </div>
              <div className="grid grid-cols-5 gap-2 mt-2">
                <div
                  className="col-span-2 border-2 p-2"
                  style={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                  }}
                >
                  {map(linkedByRobots, (item, index) => {
                    let selectedRobot = find(robots, (item2, index) => {
                      return item2?._id === item;
                    });
                    let convertedSymbol = get(selectedRobot, "symbol")?.replace(
                      "USDT",
                      ""
                    );
                    return (
                      <div className="flex justify-start py-1">
                        <span className="pr-1">
                          <Image
                            src={getCoinTypeIcon(toLower(convertedSymbol))}
                            style={{ width: 25, height: 25 }}
                            className=" pl-0.5"
                          />
                        </span>
                        <span>{selectedRobot?.symbol}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="col-span-1 flex justify-center items-center">
                  <DollarCircleOutlined /> <ArrowRightOutlined />
                </div>
                <div
                  className="col-span-2 border-2 p-2"
                  style={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                  }}
                >
                  {map(linkedRobots, (item, index) => {
                    let selectedRobot = find(robots, (item2, index) => {
                      return item2?._id === item;
                    });
                    let convertedSymbol = get(selectedRobot, "symbol")?.replace(
                      "USDT",
                      ""
                    );
                    return (
                      <div className="flex justify-start py-1">
                        <span className="pr-1">
                          <Image
                            src={getCoinTypeIcon(toLower(convertedSymbol))}
                            style={{ width: 25, height: 25 }}
                            className=" pl-0.5"
                          />
                        </span>
                        <span>{selectedRobot?.symbol}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </ConfirmationModal> */}
          </Spin>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
  updateDepositActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoLinkRobotPage);
