import { Skeleton } from "antd";
import { get, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import io from "socket.io-client";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { socketUrl } from "../../../utilities/startUp";

// markup
const SymbolMaxMinOrderCheck = (props) => {
  // const location = useLocation();
  //   console.log(price);
  const [priceValue, setPriceValue] = useState({});
  const { symbol, record } = props;
  // console.log(symbol);
  // console.log(coinStructure);

  useEffect(() => {
    if (!isEmpty(symbol)) {
      
      const socket = io(socketUrl, {
        origins: socketUrl,
        query: {
          symbols: [symbol],
        },
      });

      socket.on("connect", () => {
        // console.log("Connect to socket");
      });

      socket.on("common", (data) => {
        let priceData = get(data, symbol);
        setPriceValue(priceData || {});

        if (props?.onSetPrice) {
          props.onSetPrice(priceData);
        }
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [symbol]);

  const _renderPrice = useMemo(() => {
    let minPrice = get(record, "minPrice");
    let maxPrice = get(record, "maxPrice") || 100000000;
    let error = false;
    if (
      get(priceValue, "price") < minPrice ||
      get(record, "balanceOrder") === 60
    ) {
      error = true;
    }
    if (
      get(priceValue, "price") >= maxPrice &&
      get(record, "balanceOrder") === 0
    ) {
      error = true;
    }

    return (
      <span>
        <>
          <div className={`text-sm text-center ${error ? "text-red-500" : ""}`}>
            {get(record, "balanceOrder") || 0}
          </div>
        </>
      </span>
    );
  }, [priceValue]);

  return <React.Fragment>{_renderPrice}</React.Fragment>;
};

export default SymbolMaxMinOrderCheck;
