import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Column } from "@ant-design/plots";
import { DatePicker, Popover, Tooltip } from "antd";
import {
  cloneDeep,
  filter,
  forIn,
  get,
  has,
  isEmpty,
  isUndefined,
  map,
  set,
} from "lodash";
import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfileDailyProfit from "../../../newApi/robots/getProfileDailyProfit";
import getProfileRobotProfitSummary from "../../../newApi/robots/getProfileRobotProfitSummary";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { robotStrategyDataKey } from "../../../utilities/data";
import { numberDecimal, timeZone } from "../../../utilities/startUp";
import moment2 from "moment-timezone";
import getProfileDailyProfit2 from "../../../newApi/robots/getProfileDailyProfit";
import getProfileRobotProfitSummary2 from "../../../newApi/robots/getProfileRobotProfitSummary2";
import getProfileTotalRobotProfit from "../../../newApi/robots/getProfileTotalRobotProfit";

const tooltipTextColor = "#00d928";
const ProfitDetails = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [robotList, setRobotList] = useState([]);
  const [date, setDate] = useState([]);
  const [type, setType] = useState("HF");
  const [dates, setDates] = useState([null, null]);
  // const [hackValue, setHackValue] = useState(null);
  // const [value, setValue] = useState(null);
  const [filterGroup, setFilterGroup] = useState({});
  // const [checkAll, setCheckAll] = useState(false);
  const [userTotalProfit, setUserTotalProfit] = useState(0);
  const [capitalAmount, setCapitalAmount] = useState(0);
  // const [dateRange, setDateRange] = useState([]);
  const [firstPlayDate, setFirstPlayDate] = useState(null);
  const [changeData, setChangeData] = useState(false);
  const { t } = useTranslation();

  // const [profile, setProfile] = useState({});
  // const user = get(props, "users.user");
  const profile = get(props, "profile");

  useEffect(() => {
    //getSymbol();
    // getActiveRobot();
    getTotalProfit();
    // console.log("heree");
    getData();
    if (!isEmpty(profile)) {
      let capital = get(profile, "wallet.highFrequency.capital");
      let capitalAmountN = 0;
      forIn(capital, (value, key) => {
        capitalAmountN = capitalAmountN + value;
      });
      let limit = get(profile, "wallet.highFrequency.limit");
      let limitAmount = 0;
      forIn(limit, (value, key) => {
        limitAmount = limitAmount + value;
      });

      // console.log("capitalAmount,,", capitalAmountN);
      // console.log("limitAmount,,", limitAmount);

      if (
        isNaN(capitalAmountN) ||
        isUndefined(capitalAmountN) ||
        capitalAmountN === 0
      ) {
        setCapitalAmount(limitAmount);
      } else {
        setCapitalAmount(capitalAmountN);
      }
    }
  }, [props.profile]);

  // useEffect(() => {
  //   getData();
  // }, [totalAmount]);

  useEffect(() => {
    getData();
    getTotalProfit();
  }, [filterGroup, type]);

  // useEffect(() => {
  //   getSymbol();
  // }, [type]);

  function getData(skip) {
    if (isNaN(parseInt(skip))) {
      skip = 0;
    } else {
      skip = parseInt(skip);
    }
    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getProfileDailyProfit2(
        "all",
        0,
        //getDailyProfitReport('all', 0,
        {
          profileIdIn: robotProfilesData,
          robotType: robotStrategyDataKey.usdt,
          liquidation: 0,
          //strategyCode: type,
          profitTypeNe: 1,
          type: "day",
          ignoreProfitNe: 1,
          //symbol: symbol,
          ...filterGroup,
        }
      )
        .then((res) => {
          // console.log("profit", res);

          let data = get(res, "data");
          let startAndEndDate = [];
          let temp_dataSource = [];
          let diffDays = 7;

          if (isEmpty(date)) {
            let myDate = moment2()
              .tz(timeZone)
              .startOf("D")
              .subtract(6, "d")
              .format("YYYY-MM-DD");
            // let testDate = new Date(myDate);

            let firstDate = new Date();
            firstDate.setDate(firstDate.getDate() - 6);
            // console.log(firstDate, firstDate.toDateString());

            startAndEndDate = [myDate, new Date()];
          } else {
            startAndEndDate = cloneDeep(date);
            diffDays =
              findDayDifference(startAndEndDate[0], startAndEndDate[1]) + 1;
          }
          let startDate = startAndEndDate[0];
          let endDate = startAndEndDate[1];
          let singleDay = startDate;

          for (let index = 0; index < diffDays; index++) {
            if (index != 0) {
              singleDay = moment2(singleDay).tz(timeZone).add(1, "days");
            }

            let filtered_data = filter(data, (item) => {
              //return get(item,"_id.date") === moment(singleDay).format('YYYY-MM-DD');
              return (
                get(item, "date") ===
                moment2(singleDay).tz(timeZone).format("YYYY-MM-DD")
              );
            });

            let totalProfitDay = 0;
            if (!isEmpty(filtered_data)) {
              filtered_data.forEach((element) => {
                let totalProfit = get(element, "totalProfit");
                totalProfitDay = totalProfitDay + totalProfit;
              });
            }

            let returnPercentage = 0;
            let allCapital = get(profile, "wallet.highFrequency.capital");
            let allLimit = get(profile, "wallet.highFrequency.limit");
            let capitalAmount = 0;

            if (isEmpty(allCapital)) {
              forIn(allLimit, (value, key) => {
                capitalAmount = capitalAmount + value;
              });
            } else {
              forIn(allCapital, (value, key) => {
                capitalAmount = capitalAmount + value;
              });
            }
            // console.log("capital", capitalAmount);

            if (totalProfitDay !== 0) {
              if (capitalAmount === 0) {
                // console.log("here");
                returnPercentage = 0;
              } else {
                totalProfitDay = Math.round(totalProfitDay * 100) / 100;
                returnPercentage = (totalProfitDay / capitalAmount) * 100;
                returnPercentage = Math.round(returnPercentage * 100) / 100;
                //totalProfitDay = formatAmount(totalProfitDay);
              }
            }

            let correctValue = {
              totalProfitDay: totalProfitDay,
              returnPercentage: returnPercentage,
              _id: moment(singleDay).format("L"),
            };

            temp_dataSource.push(correctValue);
          }

          // console.log("temp_dataSource", temp_dataSource);
          setDataSource(temp_dataSource);
          setChangeData(!changeData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function findDayDifference(date1, date2) {
    return Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
  }

  function getTotalProfit() {
    // getProfileRobotProfitReport(1, 0, {
    //   profileId: get(profile, "_id"),
    //   type: "summary",
    //   strategyCode: type,
    // })
    //   .then((res) => {
    //     let profit_total = get(res, "totalProfit");
    //     setUserTotalProfit(profit_total);
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //   });
    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfilesData,
        robotType: robotStrategyDataKey.usdt,
        liquidation: 0,
        ignoreProfitNe: 1,
      })
        .then((res) => {
          // console.log("total", res);
          // console.log("totalProfit", get(res, "totalProfit"));
          setFirstPlayDate(get(res, "startDate"));
          setUserTotalProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  //console.log(dataSource);

  // console.log("capitalAmount", capitalAmount);

  let currentDay = moment(moment(new Date()).format("YYYY-MM-DD"));
  let playedDay = 0;
  if (firstPlayDate !== null) {
    let momentFormatFirstPlay = moment(
      moment(new Date(firstPlayDate)).format("YYYY-MM-DD")
    );
    playedDay = currentDay.diff(momentFormatFirstPlay, "days");
  }

  const renderType = [
    {
      text: t("totalAmt", sourceKey.highFrequency),
      value: formatDecimalNumber(capitalAmount, 2) || 0,
      render: (value, record) => {
        return (
          <div class="col-span-3 border-left my-4" style={{borderLeft:"1px solid #ff0006"}}>
            <div class="text-xs font-semibold mb-1 pl-2">{record?.text || ""}</div>
            <div class="text-xl font-semibold pl-2" style={{color:"#00008B"}}>
              {value || ""}
              <span style={{ fontSize: "12px" }}> USDT</span>
            </div>
          </div>
        );
      },
    },
    {
      text: t("activeDays", sourceKey.highFrequency),
      value: playedDay || 0,
      render: (value, record) => {
        return (
          <div class="col-span-1 border-left my-4" style={{borderLeft:"1px solid #ff0006"}}>
            <div class="text-xs font-semibold mb-1 pl-2">{record?.text || ""}</div>
            <div class="text-xl font-semibold pl-2">{value || 0}</div>
          </div>
        );
      },
    },
    {
      text: t("totalProfit", sourceKey.highFrequency),
      value: formatDecimalNumber(userTotalProfit || 0, 2),
      render: (value, record) => {
        return (
          <div class="col-span-3" style={{borderLeft:"1px solid #ff0006"}} >
            <div class="text-xs font-semibold mb-1 pl-2">{record?.text || ""}</div>
            <div class="text-xl font-semibold pl-2" style={{color:"#4CAF50"}}>
              {value || ""}
              <span style={{ fontSize: "12px" }}> USDT</span>
            </div>
          </div>
        );
      },
    },
    {
      text: t("return", sourceKey.highFrequency),
      value: isNaN(
        formatDecimalNumber((userTotalProfit / capitalAmount) * 100 || 0, 2)
      )
        ? 0.0
        : `${
            formatDecimalNumber(
              (userTotalProfit / capitalAmount) * 100 || 0,
              2
            ) || 0
          }%`,
      render: (value, record) => {
        return (
          <div class="col-span-1" style={{borderLeft:"1px solid #ff0006"}}>
            <div class="text-xs font-semibold mb-1 pl-2">{record?.text || ""}</div>
            <div class="text-xl font-semibold pl-2">{value || ""}</div>
          </div>
        );
      },
    },
  ];
  const popOverRobotAndDate = () => {
    let robotArray = cloneDeep(robotList);
    // let dateArray = cloneDeep(date);

    const disabledDate = (current) => {
      // if (!get(dateRange, "[0]") || !get(dateRange, "[1]")) {
      //   return true;
      // } else {
      //   if (get(dates, "[0]") !== null || get(dates, "[1]") !== null) {
      //     let firstDateRange = moment(get(dateRange, "[0]"));
      //     let lastDateRange = moment(get(dateRange, "[1]"));
      //     // if (current > lastDateRange || current < firstDateRange) {
      //     if (
      //       current.isAfter(lastDateRange, "days") ||
      //       current.isBefore(firstDateRange, "days")
      //     ) {
      //       return true;
      //     } else {
      //       const tooLate = dates[0] && current.diff(dates[0], "days") > 6;
      //       const tooEarly = dates[1] && dates[1].diff(current, "days") > 6;
      //       return !!tooEarly || !!tooLate;
      //     }
      //   } else {
      //     current = moment(moment(current).format("YYYY-MM-DD"));
      //     let start = moment(moment(dateRange[0]).format("YYYY-MM-DD"));
      //     let end = moment(moment(dateRange[1]).format("YYYY-MM-DD"));
      //     return current && (current.isBefore(start) || current.isAfter(end));
      //   }
      // }
      //
      // if (!dates) {
      //   return false;
      // }
      // if (dates) {
      //   const tooLate = dates[0] && current.diff(dates[0], "days") > 6;
      //   const tooEarly = dates[1] && dates[1].diff(current, "days") > 6;
      //   return !!tooEarly || !!tooLate;
      // } else {
      //   current = moment(moment(current).format("YYYY-MM-DD"));
      //   let start = moment(moment(dateRange[0]).format("YYYY-MM-DD"));
      //   let end = moment(moment(dateRange[1]).format("YYYY-MM-DD"));
      //   return current && (current.isBefore(start) || current.isAfter(end));
      // }
    };

    const onOpenChange = (open) => {
      if (open) {
        // setHackValue([null, null]);
        setDates([null, null]);
      } else {
        // setHackValue(null);
      }
    };

    const onChangeDate = (e) => {
      // console.log(e);
      let filterValue = cloneDeep(filterGroup);

      if (!isEmpty(e)) {
        let startDate = moment(e).format("YYYY-MM-DD");
        // let endDate = moment(e[1]).format("YYYY-MM-DD");
        let endDate = moment(e, "DD-MM-YYYY").add(6, "d").format("YYYY-MM-DD");

        let startDateTime = moment(startDate + " " + "00:00");
        let endDateTime = moment(endDate + " " + "23:59");

        let transformed_values = [startDateTime, endDateTime];

        set(filterValue, "timestampMin", startDateTime.toDate());
        set(filterValue, "timestampMax", endDateTime.toDate());
        setDate(transformed_values);
      } else {
        setDate([]);
        if (has(filterValue, "timestampMin")) {
          delete filterValue.timestampMin;
        }
        if (has(filterValue, "timestampMax")) {
          delete filterValue.timestampMax;
        }
      }
      setFilterGroup(filterValue);
    };

    const onChangeRobotPopOver = (visible) => {
      if (!visible) {
        setRobotList(robotArray);
      }
    };

    const onChangeDatePopOver = (visible) => {
      if (!visible) {
        // setDate(dateArray);
      }
    };

    return (
      <Popover
        arrowPointAtCenter
        content={
          <>
            {/* <DatePicker.RangePicker
              value={date}
              disabledDate={disabledDate}
              onCalendarChange={(val) => {
                console.log("val", val);
                if (val === null) {
                  setDates([null, null]);
                } else {
                  setDates(val);
                }
              }}
              onChange={onChangeDate}
              size="small"
              onOpenChange={onOpenChange}
              style={{ margin: 6 }}
            /> */}
            <DatePicker
              value={date[0]}
              // disabledDate={disabledDate}
              // onCalendarChange={(val) => {
              //   console.log("val", val);
              //   if (val === null) {
              //     setDates([null, null]);
              //   } else {
              //     setDates(val);
              //   }
              // }}
              onChange={onChangeDate}
              inputReadOnly={true}
              size="small"
              // onOpenChange={onOpenChange}
              style={{ margin: 6 }}
            />
          </>
        }
        style={{ padding: 4 }}
        onVisibleChange={onChangeDatePopOver}
        trigger="click"
        placement="bottomLeft"
      >
        <div className="flex justify-center items-center mx-4  px-2 ">
          <span
            className="py-2 px-4 text-center"
            style={{
              background: "#000000",
              borderRadius: "16px",
              color: "#FFFFFF",
              width: "150px",
              fontFamily: "Montserrat",
              fontStyle: "bold",
              fontWeight: 500,
              fontSize: "15px",
              lineHeight: "20px",
            }}
          >
            {t("chooseDate", sourceKey.highFrequency)} <DownOutlined/>
          </span>
        </div>
      </Popover>
    );
  };
  const plotBarChart = useMemo(() => {
    let data = [];
    if (!isEmpty(dataSource)) {
      dataSource.forEach((element) => {
        let robotObj = {};
        set(robotObj, "day", moment(get(element, "_id")).format("DD/MM/YYYY"));
        set(robotObj, "Profit", get(element, "returnPercentage"));
        set(robotObj, "amount", get(element, "totalProfitDay"));
        data.push(robotObj);
      });
    }

    const config = {
      data,
      xField: "day",
      yField: "amount",
      padding: [30, 0, 60, 0],
      color: "#b7c223",
      maxColumnWidth: 30,
      xAxis: {
        title: {
          text: t("days", sourceKey.highFrequency),
          style: {
            fill: "#000000",
          },
        },
        label: {
          autoHide: true,
          autoRotate: false,
          autoFit: true,
          style: {
            fill: "#000000",
          },
        },
      },
      yAxis: false,
      label: {
        position: (data) => {
          const val = parseFloat(data.Profit);
          // if (val <= -1) {
          //   return "middle";
          // } else {
          //   return "top";
          // }
          return "top";
        },
        content: (originData) => {
          const val = parseFloat(originData.Profit);
          if (val !== 0) {
            return val.toFixed(2) + "%";
          }
        },
        style: {
          // fill: "#4ade80",
          fill: "#000000",
        },
        // offsetY: -10,
        // offsetX: 5,
      },
      meta: {
        amount: {
          alias: "Profit",
        },
      },
      // smooth: true,
      // line: {
      //   color: "white",
      // },
      // point: {
      //   size: 5,
      //   style: {
      //     fill: "#FFDD02",
      //   },
      // },
      columnStyle: {
        fill: "l(270) 0:#EEFF00 0.5:#EEFF00 1:#EEFF00",
      },
    };

    return (
      <div className="theme-bg-color  mt-1 px-2 pb-2 pt-1">
        <Column
          className="pt-6 theme-bg-color rounded-3xl px-2"
          style={{ height: "400px" }}
          {...config}
          autoFit={true}
        />
      </div>
    );
  }, [changeData]);

  return (
    <>
      {/* <Divider>
        <div className="text-base font-semibold text-center">
          策略详情 Strategy Details
        </div>
      </Divider> */}

      {popOverRobotAndDate()}

      <div>
        <div class="grid grid-cols-4 mt-7">
          {map(renderType, (item, index) => {
            return <>{item?.render(item?.value, item)}</>;
          })}
        </div>
      </div>

      {plotBarChart}
    </>
  );
};

export default ProfitDetails;
