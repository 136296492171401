import { ArrowRightOutlined, DollarCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Drawer, Form, message, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { filter, find, isEmpty, map, toLower } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Image from "../../../components/general/components/Image";
import { not_foundIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getHighFrequencyRobots from "../../../newApi/robots/getHighFrequencyRobots";
import getMultiLinksRobot from "../../../newApi/robots/getMultiLinksRobot";
import multiLinkRobots from "../../../newApi/robots/multiLinkRobots";
import switchMultiLinkRobots from "../../../newApi/robots/switchMultiLinkRobots";
import { triggerModal } from "../../../redux/actions/app-actions";
import { updateDepositActiveId } from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { getCoinTypeIcon } from "../../../utilities/common-function";
import { robotStrategyDataKey } from "../../../utilities/data";
import ConfirmationModal from "../../general/components/ConfirmationModal";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import ScrollLoadWrapper from "../../general/components/ScrollLoadWrapper ";

// markup
const PAGE_SIZE = 10;
const maxHeight = "490px";
const linkedPercentageSelections = [15, 20, 25, 30, 35, 40, 45, 50];

const MultiLinkRobotPage = (props) => {
  // const accessKey = get(props.user, "accessKey");
  const { t } = useTranslation();
  // const location = useLocation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [robots, setRobots] = useState([]);
  const [linkedPercentage, setLinkedPercentage] = useState(0);
  // const [linkedByRobots, setLinkedByRobots] = useState([]);
  // const [linkedRobots, setLinkedRobots] = useState([]);
  // const [confirmVisible, setConfirmVisible] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  // const [transactions, setTransactions] = useState([]);
  const [visible, setVisible] = useState(false);

  const profile = props.profile;

  message.config({
    duration: 3,
  });
  // let product = get(props, "data");

  useEffect(() => {
    if (!isEmpty(profile) && props.edit === 2) {
      // console.log("here", props);
      let robotProfiles = get(profile, "robotProfiles");
      let selectedProfile = find(robotProfiles, (item, index) => {
        return item?.exchange === props?.platform;
      });
      getMultiLinksRobot({
        profileId: selectedProfile?.robotProfileId,
      })
        .then((res) => {
          // console.log("ress", res);
          let data = res?.data;
          data = data[0];
          if (!isEmpty(data)) {
            let linkedByRobots = data?.linkedBy || [];
            let linkedRobotsData = data?.linkedRobots || [];
            let linkPercentage = data?.linkedPercentage * 100 || 0;

            form.setFieldsValue({
              linkedBy: linkedByRobots,
              linkedRobots: linkedRobotsData,
              linkedPercentage: linkPercentage,
            });
            setSelectedData(data);
            setLinkedPercentage(linkPercentage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.profile, props.edit]);

  useEffect(() => {
    setVisible(props.visible === true);
    if (props.visible === true) {
      getData();
    }
  }, [props.visible]);

  useEffect(() => {}, [props.edit]);

  function getData() {
    let robotProfiles = get(profile, "robotProfiles");
    if (!isEmpty(robotProfiles)) {
      robotProfiles = map(robotProfiles, (item, index) => {
        return get(item, "robotProfileId");
      });
      // console.log(props?.platform, " plarform");
      setLoading(true);
      getHighFrequencyRobots({
        profileIdIn: robotProfiles,
        liquidation: 0,
        profitTypeNe: 1,
        limit: "all",
        linkRobotTypeOr: 3,
        isAdmin: true,
        exchange: props?.platform || "binance",
        enableLinkRobotOr: 0,
      })
        .then((res) => {
          // console.log("res", res);
          let data = res?.data || [];
          setRobots(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }

  function close() {
    if (props.onClose) {
      form.resetFields();
      props.onClose();
      setVisible(false);
    } else {
      setVisible(false);
    }
  }

  function getRobotDisplayText(robot) {
    // let convertedSymbol = get(robot, "symbol")?.replace("USDT", "");
    let convertedSymbol = get(robot, "symbol");
    return `${convertedSymbol} - ${get(robot, "_id")?.substring(
      get(robot, "_id").length - 4
    )} ${get(robot, "remark") ? `(${robot.remark})` : ""}`;
  }

  function onFinish(values) {
    // console.log("Form values:", values);
    form
      .validateFields()
      .then((values) => {
        // console.log(values);
        // console.log(props?.platform);
        setLoading(true);
        let robotProfiles = get(profile, "robotProfiles");
        let selectedProfile = find(robotProfiles, (item, index) => {
          return item?.exchange === props?.platform;
        });

        // console.log("selectedProfile",selectedProfile)

        switchMultiLinkRobots({
          profileId: selectedProfile?.robotProfileId,
          strategy: robotStrategyDataKey.usdt,
          enable: 1,
          clearLinkedRobots: 1,
          linkedBy: values?.linkedBy,
          linkedRobots: values?.linkedRobots,
          linkedPercentage: linkedPercentage / 100,
        })
          .then((res) => {
            // console.log(res);
            message.success(t("success"));
            setLoading(false);
            // close();
            if (props.onSuccess) {
              props.onSuccess();
            }
          })
          .catch((err) => {
            console.log(err);
            message.error(err?.message);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // setLinkedByRobots(values?.linkedBy || []);
    // setLinkedRobots(values?.linkedRobots || []);
    // setConfirmVisible(true);
  }

  return (
    <React.Fragment>
      <Drawer
        visible={visible}
        width="100%"
        className="body-no-padding"
        closable={false}
        onClose={() => {
          close();
        }}
      >
        <Layout>
          <Spin spinning={loading}>
            <NavHeader
              suffix={
                <span className="inline-block text-base text-right font-semibold">
                  {/* 机器人记录{" "}
                  <MediaQuery maxWidth={440}>
                    <br />
                  </MediaQuery> */}
                  {t("multi", sourceKey.highFrequency)}
                </span>
              }
              showBack={true}
              onBack={() => {
                close();
              }}
            >
              <div className="p-4 " style={{ minHeight: "140px" }}>
                {!isEmpty(robots) ? (
                  <Form
                    form={form}
                    onFinish={onFinish}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") form.submit();
                    }}
                  >
                    <div className="grid grid-cols-4 gap-2">
                      <div className="col-span-4 font-bold">
                        <Form.Item
                          name="linkedPercentage"
                          rules={[
                            {
                              required: true,
                              message: (
                                <React.Fragment>
                                  {/* 请选择贡献百分比 Please select contribute percentage */}
                                  {t(
                                    "contributePerRequired",
                                    sourceKey.highFrequency
                                  )}
                                </React.Fragment>
                              ),
                            },
                          ]}
                        >
                          <Select
                            // showSearch
                            className=""
                            value={linkedPercentage}
                            style={{ width: "100%" }}
                            placeholder={t(
                              "selectPercentage",
                              sourceKey.highFrequency
                            )}
                            onChange={(value) => {
                              setLinkedPercentage(value);
                              form.setFieldsValue({
                                linkedPercentage: value,
                              });
                            }}
                          >
                            {map(linkedPercentageSelections, (item, index) => {
                              return (
                                <Select.Option
                                  value={item}
                                  key={`option-${index}`}
                                >
                                  {item} %
                                </Select.Option>
                              );
                            })}
                          </Select>
                          {/* <div className="flex items-center  flex-wrap"></div> */}
                          {/* < style={{ display: "none" }} /> */}
                        </Form.Item>
                      </div>
                      <div className="col-span-4 ">
                        <div
                          className="grid grid-cols-5"
                          style={{
                            // maxHeight: maxHeight,
                            // overflowY: "scroll",
                            width: "100%",
                          }}
                        >
                          <div className="col-span-3"></div>
                          <div className="col-span-1 text-xs font-bold">
                            {" "}
                            {t("linkedRobot", sourceKey.highFrequency)}
                          </div>
                          <div className="col-span-1 text-xs font-bold">
                            {" "}
                            {t("gapOptimisation", sourceKey.highFrequency)}
                          </div>
                        </div>
                        <div
                          className="grid grid-cols-5"
                          style={{
                            maxHeight: maxHeight,
                            overflowY: "scroll",
                            width: "100%",
                          }}
                        >
                          <div className="col-span-3">
                            {map(robots, (item, index) => {
                              let convertedSymbol = get(
                                item,
                                "symbol"
                              )?.replace("USDT", "");
                              return (
                                <div
                                  className="flex justify-start pt-2"
                                  // style={{
                                  //   // marginTop: "5px",
                                  // }}
                                  style={{
                                    height: "40px",
                                    marginTop: "6.9px",
                                  }}
                                >
                                  <span className="pr-4">
                                    <Image
                                      src={getCoinTypeIcon(
                                        toLower(convertedSymbol)
                                      )}
                                      style={{ width: 25, height: 25 }}
                                      className=" pl-0.5"
                                    />
                                  </span>
                                  <span className=" font-semibold ">
                                    {getRobotDisplayText(item)}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                          <div className="col-span-1">
                            <Form.Item
                              name="linkedBy"
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写资金  */}
                                      {t(
                                        "linkedRequired",
                                        sourceKey.highFrequency
                                      )}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Checkbox.Group
                                style={
                                  {
                                    // maxHeight: maxHeight,
                                    // overflowY: "scroll",
                                    // width: "100%",
                                  }
                                }
                              >
                                {map(robots, (item, index) => {
                                  return (
                                    <div
                                      className="flex justify-start "
                                      style={{
                                        height: "40px",
                                        marginTop: ` ${
                                          index === 0 ? "9.0px" : "7.2px"
                                        }`,
                                      }}
                                      // style={{
                                      //   marginTop: "7.9px",
                                      // }}
                                    >
                                      <Checkbox
                                        value={item?._id}
                                        // className="center"
                                      ></Checkbox>
                                    </div>
                                  );
                                })}
                              </Checkbox.Group>
                            </Form.Item>
                          </div>
                          <div className="col-span-1">
                            <Form.Item
                              name="linkedRobots"
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写资金  */}
                                      {t(
                                        "linkedRequired",
                                        sourceKey.highFrequency
                                      )}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Checkbox.Group
                                style={
                                  {
                                    // maxHeight: maxHeight,
                                    // overflowY: "scroll",
                                    // width: "100%",
                                  }
                                }
                              >
                                {map(robots, (item, index) => {
                                  return (
                                    <div
                                      className="flex justify-start "
                                      style={{
                                        height: "40px",
                                        marginTop: ` ${
                                          index === 0 ? "9.0px" : "7.2px"
                                        }`,
                                      }}
                                      // style={{
                                      //   marginTop: "7.9px",
                                      // }}
                                    >
                                      <Checkbox
                                        value={item?._id}
                                        // className="center"
                                      ></Checkbox>
                                    </div>
                                  );
                                })}
                              </Checkbox.Group>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-2">
                      <Button
                        className="main-button-color"
                        htmlType="submit"
                        style={{
                          width: "100%",
                        }}
                        loading={loading}
                      >
                        {t("submit")}
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <div className="mt-7">
                    <img
                      src={not_foundIcon}
                      className=" object-contain h-full w-full"
                      style={{ height: "10vh" }}
                    />

                    <br></br>
                    <div className="flex justify-center items-center">
                      <div
                        className="flex justify-center items-center font-black text-l text-center mb-2 text-gray-400"
                        style={{ width: 310 }}
                      >
                        {t("noRecords", sourceKey.highFrequency)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </NavHeader>
          </Spin>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
  updateDepositActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(MultiLinkRobotPage);
