import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EyeOutlined,
  FileProtectOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  message,
  Radio,
  Skeleton,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { navigate } from "gatsby-link";
import {
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  indexOf,
  isEmpty,
  isNumber,
  map,
  omit,
  set,
  toLower,
  uniqBy,
} from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Image from "../../../components/general/components/Image";
import { not_foundIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import allRobotActions from "../../../newApi/robots/allRobotActions";
import disableLinkedRobots from "../../../newApi/robots/disableLinkedRobots";
import getHighFrequencyRobots from "../../../newApi/robots/getHighFrequencyRobots";
import getProfileRobotProfitSummary from "../../../newApi/robots/getProfileRobotProfitSummary";
import getProfileRobotProfitSummary2 from "../../../newApi/robots/getProfileRobotProfitSummary2";
import getRobotProfiles from "../../../newApi/robots/getRobotProfiles";
import getSymbolList from "../../../newApi/robots/getSymbolList";
import switchAutoLinkRobots from "../../../newApi/robots/switchAutoLinkRobots";
import {
  updateActiveId,
  updatePreviousPagination,
} from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import {
  formatDecimalNumber,
  getCoinTypeIcon,
  isValidNumber,
} from "../../../utilities/common-function";
import { platformLogo, robotStrategyDataKey } from "../../../utilities/data";
import {
  allRobotsActionsTypes,
  convertPrestopToNumber,
} from "../../../utilities/profile";
import {
  AllExchangePlatforms,
  numberDecimal,
} from "../../../utilities/startUp";
import ConfirmationModal from "../../general/components/ConfirmationModal";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import ProfitDetails from "../../homepage/pages/ProfitDetails";
import AutoLinkRobotPage from "../components/AutoLinkRobotPage";
import MultiLinkRobotPage from "../components/MultiLinkRobotPage";
import { robotUserCanRecoverErrorStatus } from "../config";
import SymbolMinPrice from "../components/SymbolMinPrice";
import SymbolMaxMinOrderCheck from "../components/SymbolMaxMinOrderCheck";

const PAGE_SIZE = 50;

const robotTypes = ["HF1", "HF3"];
//let displayRobot = [];

const notFormatCoin = ["BTC", "ETH"];
const tooltipTextColor = "#00d928";

// markup
const RobotPage = (props) => {
  // const location = useLocation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [arrayLoading, setArrayLoading] = useState(true);
  const [overviewData, setOverviewData] = useState([[], [], [], []]);
  const [total, setTotal] = useState([[0], [0], [0], [0]]);
  const [robotTotal, setRobotTotal] = useState(0);
  const [displayRobot, setDisplayRobot] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("binance");
  const [profile, setProfile] = useState({});
  const [robotProfiles, setRobotProfiles] = useState([]);
  const [profileRobot, setProfileRobot] = useState({});
  const [marketPrices, setMarketPrices] = useState([]);
  const [page, setPage] = useState(1);
  const [userTotalProfit, setUserTotalProfit] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  let accessKey = get(props.user, "accessKey");
  let user_profile = get(props.user.user, "profile");
  const [sort, setSort] = useState({});
  const [allActionVisible, setAllActionVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionUpdate, setActionUpdate] = useState(false);
  const [actionUpdate2, setActionUpdate2] = useState(false);
  const [multiLinkVisible, setMultiLinkVisible] = useState(false);
  const [autoLinkVisible, setAutoLinkVisible] = useState(false);
  const [multiLinkValue, setMultiLinkValue] = useState(0);
  const [isEdit, setIsEdit] = useState(0);
  const [balanceStockPNL, setBalanceStockPNL] = useState(null);

  // let interval;
  // let accessKey = get(props.user, "accessKey");
  // let user_profile = get(props.user.user, "profile");
  // const MINUTE_MS = 3000;
  const { t } = useTranslation();

  let timeoutFunc;

  useEffect(() => {
    getProfileAPI();

    //setArrayLoading(true);
    //getData(1)

    // getData(3)profile
    //getData(4)
    //setArrayLoading(false);
    if (!isEmpty(get(props, "product.robotListing"))) {
      setPage(get(props, "product.robotListing.previousPage"));
      setSort(get(props, "product.robotListing.previousSorting"));
    }
    // console.log(props);
  }, []);

  useEffect(() => {
    getData(2, (page - 1) * PAGE_SIZE);
  }, [page, sort, actionUpdate2]);

  useEffect(() => {
    /// get tota profit
    getData(2, (page - 1) * PAGE_SIZE);

    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfilesData,
        robotType: robotStrategyDataKey.usdt,
        liquidation: 0,
      })
        .then((res) => {
          // console.log("total", res);
          // console.log("totalProfit", get(res, "totalProfit"));
          // setFirstPlayDate(get(res, "startDate"));
          setUserTotalProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profile]);

  useEffect(() => {
    //// get Robot profiles
    if (!isEmpty(profile)) {
      let robotProfiles = get(profile, "robotProfiles");
      let robotProfileIds = map(robotProfiles, "robotProfileId");
      if (!isEmpty(robotProfiles)) {
        getRobotProfiles({
          _idIn: robotProfileIds,
          isAdmin: true,
        })
          .then((res) => {
            // console.log("res",res);
            let data = get(res, "data");
            setRobotProfiles(data);
          })
          .catch((err) => {
            console.log(err);
            message.error(err?.message);
          });
      } else {
        setRobotProfiles([]);
      }
    }
  }, [profile, actionUpdate]);

  useEffect(() => {
    if (!isEmpty(robotProfiles)) {
      let selectedRobot = filter(robotProfiles, (item, index) => {
        return get(item, "exchange") === selectedPlatform;
      });
      selectedRobot = selectedRobot[0];

      let autoLink =
        get(selectedRobot, `settings.${robotStrategyDataKey.usdt}.autoLink`) ||
        0;
      let manualLink =
        get(
          selectedRobot,
          `settings.${robotStrategyDataKey.usdt}.manualLink`
        ) || 0;

      // console.log("autoLink", autoLink);
      if (autoLink === 1) {
        setMultiLinkValue(1);
      } else if (manualLink == 1) {
        setMultiLinkValue(2);
      } else {
        setMultiLinkValue(0);
      }

      setProfileRobot(selectedRobot);
    }
    getData(2, (page - 1) * PAGE_SIZE);
  }, [selectedPlatform, robotProfiles]);

  // useEffect(() => {
  //   /// get market price
  //   let dataSource = overviewData[1];

  //   if (!isEmpty(dataSource)) {
  //     let symbolList = map(dataSource, (item, index) => {
  //       return get(item, "symbol");
  //     });

  //     symbolList = uniqBy(symbolList);

  //     let promises = [];
  //     // console.log("symbol list", symbolList);
  //     forEach(dataSource, (item, index) => {
  //       promises.push(
  //         getSymbolList({
  //           symbolExact: get(item, "symbol"),
  //           exchange: get(item, "exchange"),
  //         })
  //       );
  //     });

  //     Promise.all(promises).then((array) => {
  //       let newData = map(array, (res, index) => {
  //         let data = get(res, "data[0]");
  //         let finalValues = {};
  //         finalValues = {
  //           symbol: get(data, "symbol"),
  //           price: get(data, "price"),
  //           increase: false,
  //         };

  //         if (!isEmpty(marketPrices)) {
  //           let currentPrice = find(marketPrices, (item2, index) => {
  //             return get(item2, "symbol") === get(data, "symbol");
  //           });
  //           if (get(data, "price") >= get(currentPrice, "price")) {
  //             set(finalValues, "increase", true);
  //           }
  //         }
  //         return finalValues;
  //       });
  //       setMarketPrices(newData);
  //       // setNewCoinData(newData);
  //     });
  //   }
  // }, [overviewData]);

  useEffect(() => {
    if (
      isNumber(get(props, "product.robotListing.pagePosition")) &&
      get(props, "product.robotListing.pagePosition") !== 0 &&
      !isEmpty(marketPrices) &&
      !isEmpty(overviewData[1])
    ) {
      window?.scrollTo({
        // top: get(props, "product.robotListing.pagePosition"),
        top: 730,
        behavior: "smooth",
      });
      props.updatePreviousPagination({});
    }
  }, [marketPrices]);

  function getData(activeKey, skip) {
    if (!isEmpty(profile) && activeKey === 2) {
      setArrayLoading(true);
      let robotProfiles = get(profile, "robotProfiles");
      if (!isEmpty(robotProfiles)) {
        robotProfiles = map(robotProfiles, (item, index) => {
          return get(item, "robotProfileId");
        });
        // console.log(robotProfiles);
        getHighFrequencyRobots({
          profileIdIn: robotProfiles,
          sort,
          exchange: selectedPlatform,
          liquidation: 0,
          profitTypeNe: 1,
          limit: PAGE_SIZE,
          skip: skip,
        })
          .then((res) => {
            let newOverviewData = cloneDeep(overviewData);
            set(newOverviewData, `[${activeKey - 1}]`, get(res, "data"));
            let newTotal = cloneDeep(total);
            set(newTotal, `[${activeKey - 1}]`, get(res, "total"));
            setTotal(newTotal);
            setOverviewData(newOverviewData);
            let data = get(res, "data");
            if (!isEmpty(data)) {
              let hf1DataSource = [];
              let hf3DataSource = data;
              let robotDisplayTemp = [];
              // if (!isEmpty(hf1DataSource)) {
              //   robotDisplayTemp.push(robotTypes[0]);
              // }
              if (!isEmpty(hf3DataSource)) {
                robotDisplayTemp.push(robotTypes[1]);
              }
              setDisplayRobot(robotDisplayTemp);
              setArrayLoading(false);
            } else {
              setArrayLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setArrayLoading(false);
          });
      } else {
        setArrayLoading(false);
      }
    }
  }

  function getProfileAPI() {
    if (get(props.user, "user.profile._id")) {
      getProfiles(1, 0, {
        _id: get(props.user, "user.profile._id"),
      }).then((res) => {
        // console.log("res", res);
        let data = get(res, "data[0]");
        setProfile(data);

        // let robotProfiles = get(data, "robotProfiles");
        // if (!isEmpty(robotProfiles)) {
        //   robotProfiles = map(robotProfiles, (item, index) => {
        //     return get(item, "robotProfileId");
        //   });
        // }
        // console.log("robotProfiles", robotProfiles);
        // getRobotProfiles({
        //   _idIn: robotProfiles,
        // })
        //   .then((res2) => {
        //     // console.log("res getRobotProfiles", res2);
        //     let data2 = get(res2, "data");
        //     setAllRobotProfiles(data2);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      });
    }
  }

  const _robotStatusClass = (robot) => {
    let color = "";
    let exchange = get(robot, "exchange");
    let robotProfileData = filter(robotProfiles, (item, index) => {
      return get(item, "exchange") === exchange;
    });
    robotProfileData = robotProfileData[0];
    let limit = get(robotProfileData, "robotFundLimit.highFrequency.limit");
    let maxAmount = limit * 0.98;
    // console.log(robot);

    if (robot?.customLiquidation === 1) {
      color = "rounded-md  bg-green-500 text-white";
    } else if (
      indexOf(robotUserCanRecoverErrorStatus, get(robot, "status")) !== -1 ||
      get(robot, "systemPreStop") === 1 ||
      get(robotProfileData, "robotFundLimit.highFrequency.used") > maxAmount
    ) {
      color = "rounded-md bg-red-500 text-white";
    } else {
      if (
        get(robot, "isPreStop") !== 0 ||
        get(robotProfileData, "settings.highFrequency.preStop") === 1 ||
        get(robotProfileData, "settings.highFrequency.preStop") === 2
      ) {
        color = "rounded-md yellow-bg-main-color-gradient";
      }
    }

    return color;
  };

  const _renderTable = (key, robotType, dataSource) => {
    let columns = [];

    //Do the dataSource checking, remove dummy dataSource below.
    dataSource = [];
    let dataTotal = 0;

    switch (key) {
      case 2:
        columns = [
          {
            title: (
              <React.Fragment>
                <div className="text-sm text-center">
                  {/* 币种 <br /> */}
                  {/* {t("coinType", sourceKey.highFrequency)} */}
                </div>
              </React.Fragment>
            ),
            dataIndex: "symbol",
            key: "symbol",
            width: 180,
            fixed: "left",
            render: (value, record) => {
              let convertedSymbol = get(record, "symbol")?.replace("USDT", "");
              let symbol = get(record, "symbol");
              let marketPricesData = find(marketPrices, (item, index) => {
                return get(item, "symbol") === symbol;
              });
              return {
                props: {},
                children: (
                  <div
                    className={`flex justify-start px-0.5  ${_robotStatusClass(
                      record
                    )}`}
                  >
                    <div className="flex grid grid-cols-12">
                      <div
                        className="flex justify-center items-center col-span-3"
                        style={{ width: "25px" }}
                      >
                        <Image
                          src={getCoinTypeIcon(toLower(convertedSymbol))}
                          style={{ width: 25, height: 25 }}
                          className=" mt-0.5 mr-1 pl-0.5"
                        />
                      </div>

                      <div
                        className={`flex text-xs text-center py-1 cursor-pointer col-span-5`}
                        style={{ width: "160px" }}
                      >
                        <div className="">
                          <div className="flex justify-start">
                            {`${convertedSymbol} ${
                              get(record, "remark") ? `(${record.remark})` : ""
                            }`}
                          </div>
                          <div
                            className="flex justify-start"
                            style={{ fontSize: "0.5rem" }}
                          >
                            <span>
                              ID:{" "}
                              {get(record, "_id")?.substring(
                                get(record, "_id").length - 4
                              )}{" "}
                              {get(record, "enableLinkRobot") === 1
                                ? `/ ${get(record, "linkedRobotId")?.substring(
                                    get(record, "linkedRobotId").length - 4
                                  )}`
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <SymbolMinPrice
                        record={record}
                        symbol={record?.symbol}
                        coinStructure={props?.product?.coinUnitStructure}
                      />
                    </div>
                  </div>
                ),
              };
            },
          },
          {
            title: (
              <React.Fragment>
                <div
                  className="text-sm  text-center cursor-pointer"
                  onClick={() => {
                    if (get(sort, "profitEarned") == 1) {
                      setSort({
                        profitEarned: -1,
                      });
                    } else if (get(sort, "profitEarned") == -1) {
                      setSort(omit(sort, "profitEarned"));
                    } else {
                      setSort({
                        profitEarned: 1,
                      });
                    }
                  }}
                >
                  {t("pnl", sourceKey.highFrequency)}
                  {get(sort, "profitEarned") == 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "profitEarned") == -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
            ),
            dataIndex: "profitEarned",
            width: 79,
            key: "profitEarned",
            render: (value, record) => {
              let symbol = get(record, "symbol");
              let marketPricesData = find(marketPrices, (item, index) => {
                return get(item, "symbol") === symbol;
              });
              let balanceStockPNL11 =
                (marketPricesData?.price - get(record, "avgStockPrice")) *
                get(record, "balanceOrderAmount");
              let netProfit = value + balanceStockPNL11;

              return (
                <div className="text-sm text-center">
                  <div
                    className={`text-sm text-center ${
                      isValidNumber(parseFloat(value))
                        ? parseFloat(value) > 0
                          ? "text-green-500"
                          : parseFloat(value) < 0
                          ? "text-red-500"
                          : ""
                        : ""
                    }`}
                  >
                    {formatDecimalNumber(parseFloat(value || 0), numberDecimal)}
                  </div>
                  {/* {isValidNumber(parseFloat(netProfit)) && ( */}
                  <div style={{ position: "relative" }}>
                    <span
                      className={
                        netProfit !== null &&
                        !isNaN(netProfit) &&
                        netProfit !== 0
                          ? netProfit > 0
                            ? "text-green-500"
                            : "text-red-500"
                          : "text-white"
                      }
                      style={{ fontSize: "0.65rem" }}
                    >
                      {/* ({formatDecimalNumber(netProfit || 0, 2)}){" "}
                      <div
                        style={{
                          position: "absolute",
                          top: "20%",
                          right: "-5%",
                        }}
                      >
                        <Tooltip
                          title={
                            <div style={{ color: tooltipTextColor }}>
                              {t("netProfit", sourceKey.highFrequency)}
                            </div>
                          }
                          placement="right"
                          size="10px"
                        >
                          <InfoCircleOutlined
                            style={{
                              verticalAlign: "middle",
                              color: "white",
                              fontSize: "10px",
                            }}
                          />
                        </Tooltip>
                      </div> */}
                    </span>
                  </div>
                  {/* )} */}
                </div>
              );
            },
          },
          {
            title: (
              <React.Fragment>
                <div
                  className="text-sm  text-center cursor-pointer"
                  onClick={() => {
                    if (get(sort, "balanceOrder") === 1) {
                      setSort({
                        balanceOrder: -1,
                      });
                    } else if (get(sort, "balanceOrder") === -1) {
                      setSort(omit(sort, "balanceOrder"));
                    } else {
                      setSort({
                        balanceOrder: 1,
                      });
                    }
                  }}
                >
                  {t("balanceOrder", sourceKey.highFrequency)}
                  {get(sort, "balanceOrder") === 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "balanceOrder") === -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
              // <React.Fragment>
              //   <div className="text-sm  text-center">
              //     {/* 剩余仓数 <br /> */}
              //     {t("balanceOrder", sourceKey.highFrequency)}
              //   </div>
              // </React.Fragment>
            ),
            width: 73,
            dataIndex: "balanceOrder",
            key: "balanceOrder",
            render: (value, record) => {
              // value = find(currentOrders, ['symbol', get(record, 'symbol')]);
              
              return (
                <SymbolMaxMinOrderCheck symbol={record?.symbol} record={record} />

              );
            },
          },
          {
            title: (
              <React.Fragment>
                
                <div
                  className="text-sm  text-center cursor-pointer"
                  onClick={() => {
                    if (get(sort, "totalCover") === 1) {
                      setSort({
                        totalCover: -1,
                      });
                    } else if (get(sort, "totalCover") === -1) {
                      setSort(omit(sort, "totalCover"));
                    } else {
                      setSort({
                        totalCover: 1,
                      });
                    }
                  }}
                >
                  {t("cover", sourceKey.highFrequency)}
                  {get(sort, "totalCover") === 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "totalCover") === -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
            ),
            dataIndex: "activeStocks",
            width: 73,
            key: "activeStocks",
            render: (value, record) => {
              return (
                <div className="text-sm text-center">
                  {get(record, "totalCover") || 0}
                </div>
              );
            },
          },
          {
            title: (
              <React.Fragment>
                <div
                  className="text-sm  text-center cursor-pointer"
                  onClick={() => {
                    if (get(sort, "amount") === 1) {
                      setSort({
                        amount: -1,
                      });
                    } else if (get(sort, "amount") === -1) {
                      setSort(omit(sort, "amount"));
                    } else {
                      setSort({
                        amount: 1,
                      });
                    }
                  }}
                >
                  {t("amount", sourceKey.highFrequency)}
                  {get(sort, "amount") === 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "amount") === -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
            ),
            width: 87,
            dataIndex: "amount",
            key: "amount",
            render: (value, record) => {
              return (
                <div className="text-sm text-center">
                  {/* {robotType} / {get(record, "amount")} */}
                  {get(record, "amount")}
                </div>
              );
            },
          },
          // {
          //   title: (
          //     <React.Fragment>
          //       <div
          //         className="text-sm  text-center cursor-pointer"
          //         onClick={() => {
          //           if (get(sort, "exchange") === 1) {
          //             setSort({
          //               exchange: -1,
          //             });
          //           } else if (get(sort, "exchange") === -1) {
          //             setSort(omit(sort, "exchange"));
          //           } else {
          //             setSort({
          //               exchange: 1,
          //             });
          //           }
          //         }}
          //       >
          //         {t("platform", sourceKey.highFrequency)}
          //         {get(sort, "exchange") === 1 && (
          //           <ArrowUpOutlined
          //             style={{ color: "green" }}
          //             className="ml-2"
          //           />
          //         )}
          //         {get(sort, "exchange") === -1 && (
          //           <ArrowDownOutlined
          //             style={{ color: "red" }}
          //             className="ml-2"
          //           />
          //         )}
          //       </div>
          //     </React.Fragment>
          //   ),
          //   dataIndex: "exchange",
          //   key: "exchange",
          //   width: 95,
          //   render: (value, record) => {
          //     return (
          //       <div className="text-sm text-center">
          //         {/* {robotType} / {get(record, "amount")} */}
          //         {t(get(record, "exchange"), sourceKey.highFrequency)}
          //       </div>
          //     );
          //   },
          // },
        ];

        switch (robotType) {
          // case robotTypes[0]:
          //   dataSource = overviewData[1].filter((record) => {
          //     return !get(record, "minPrice");
          //   });
          //   break;
          case robotTypes[1]:
            dataSource = overviewData[1].filter((record) => {
              return get(record, "minPrice") || !get(record, "minPrice");
            });
            break;

          default:
            break;
        }
        dataTotal = dataSource.size;

        break;

      default:
        break;
    }

    return (
      <>
        {
          // arrayLoading ? (
          //   <>
          //     <Skeleton active rows={5}></Skeleton>
          //     <Skeleton active rows={5}></Skeleton>
          //     <Skeleton active rows={5}></Skeleton>
          //   </>
          // ) :
          isEmpty(dataSource) ? (
            <div className="mt-7">
              <img
                src={not_foundIcon}
                className=" object-contain h-full w-full"
                style={{ height: "10vh" }}
              />

              <br></br>
              <div className="flex justify-center items-center">
                <div
                  className="flex justify-center items-center font-black text-l text-center mb-2 text-gray-400"
                  style={{ width: 310 }}
                >
                  没有记录
                  <br />
                  No Data Found
                </div>
              </div>
            </div>
          ) : (
            <>
              <Table
                className="rounded-2xl  mx-4 mt-1 no-row-hover"
                scroll={{ x: 500 }}
                size={"small"}
                columns={columns}
                pagination={{
                  simple: true,
                  total: total[1],
                  current: page,
                  onChange: (page_selected) => {
                    // console.log(page_selected);
                    setPage(page_selected);
                  },
                  pageSize: PAGE_SIZE,
                  showSizeChanger: false,
                }}
                dataSource={dataSource}
                // rowClassName={(record) => {
                //   return key == 2 && get(find(robotStatus, ['robotId', get(record, 'robotId')]), 'isPreStop') ? 'bg-main-color-gradient' : ''
                // }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      switch (key) {
                        case 1:
                          props.updateActiveId(get(record, "_id"));
                          navigate(routes.futureDetails.to());
                          break;
                        case 2:
                          props.updateActiveId(get(record, "_id"));
                          const position = window.pageYOffset;
                          props.updatePreviousPagination({
                            page,
                            position,
                            sort,
                          });
                          // props.updateRobotData(record)
                          navigate(routes.highFrequencyDetails.to());
                          break;
                        case 3:
                          props.updateActiveId(get(record, "_id"));
                          navigate(routes.safetyPinDetails.to());
                          break;
                        case 4:
                          props.updateActiveId(get(record, "_id"));
                          navigate(routes.chaseDetails.to());
                          break;

                        default:
                          break;
                      }
                    }, // click row
                    onDoubleClick: (event) => {}, // double click row
                    onContextMenu: (event) => {}, // right button click row
                    onMouseEnter: (event) => {}, // mouse enter row
                    onMouseLeave: (event) => {}, // mouse leave row
                  };
                }}
              ></Table>
            </>
          )
        }
      </>
    );
  };

  function searchRobot(e, activeKey = "2") {
    let value = e.target.value;
    setSearchValue(e.target.value.trim());
    clearTimeout(timeoutFunc);
    timeoutFunc = setTimeout(() => {
      if (!isEmpty(value) && !isEmpty(profile)) {
        let robotProfiles = get(profile, "robotProfiles");
        if (!isEmpty(robotProfiles)) {
          robotProfiles = map(robotProfiles, (item, index) => {
            return get(item, "robotProfileId");
          });
          // console.log(robotProfiles);
          getHighFrequencyRobots({
            profileIdIn: robotProfiles,
            liquidation: 0,
            limit: "all",
            profitTypeNe: 1,
            symbol: value,
            skip: 0,
          })
            .then((res) => {
              // console.log("search", res);
              let newOverviewData = cloneDeep(overviewData);
              set(newOverviewData, `[${activeKey - 1}]`, get(res, "data"));
              let newTotal = cloneDeep(total);
              set(newTotal, `[${activeKey - 1}]`, get(res, "total"));
              setTotal(newTotal);
              setOverviewData(newOverviewData);
              let data = get(res, "data");
              if (!isEmpty(data)) {
                let hf1DataSource = [];
                let hf3DataSource = data;
                let robotDisplayTemp = [];
                // if (!isEmpty(hf1DataSource)) {
                //   robotDisplayTemp.push(robotTypes[0]);
                // }
                if (!isEmpty(hf3DataSource)) {
                  robotDisplayTemp.push(robotTypes[1]);
                }
                setDisplayRobot(robotDisplayTemp);
                // setArrayLoading(false);
              } else {
                // setArrayLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              message.error(err?.message);
            });
        }
      } else {
        getData(2);
      }
    }, 1000);
  }

  function _renderTableSummary(robotType, dataSource) {
    dataSource = [];
    let dataTotal = 0;

    switch (robotType) {
      // case robotTypes[0]:
      //   dataSource = overviewData[1].filter((record) => {
      //     return !get(record, "minPrice");
      //   });
      //   break;
      case robotTypes[1]:
        dataSource = overviewData[1].filter((record) => {
          return get(record, "minPrice") || !get(record, "minPrice");
        });
        break;

      default:
        break;
    }

    let selectedRobot = filter(robotProfiles, (item, index) => {
      return get(item, "exchange") === selectedPlatform;
    });

    selectedRobot = selectedRobot[0];

    dataTotal = dataSource.size;

    let numRobots =
      get(
        selectedRobot,
        `strategySummary.${robotStrategyDataKey.usdt}.totalRobots`
      ) || 0;
    // let cover = sumBy(robotProfiles, "totalCover") || 0;
    // let balance = sumBy(robotProfiles, "balanceOrder") || 0;
    // let stockAmount = sumBy(robotProfiles, "totalRobotAmount") || 0;
    let cover =
      get(
        selectedRobot,
        `strategySummary.${robotStrategyDataKey.usdt}.totalCover`
      ) || 0;
    let balance =
      get(
        selectedRobot,
        `strategySummary.${robotStrategyDataKey.usdt}.balanceOrder`
      ) || 0;
    let stockAmount =
      get(
        selectedRobot,
        `strategySummary.${robotStrategyDataKey.usdt}.totalRobotAmount`
      ) || 0;
    let pnl = 0;

    // dataSource.map((record) => {
    //   cover += toNumber(record.totalCover);
    //   balance += toNumber(record.balanceOrder);
    //   pnl += toNumber(record.profitEarned || 0);
    //   stockAmount += toNumber(record.amount || 0);
    // });

    let totalLimit = 0;
    let totalUsed = 0;

    // let selectedRobotProfiles = filter(allRobotProfiles, (item, index) => {
    //   return get(item, "exchange") === selectedPlatform;
    // });
    // selectedRobotProfiles = selectedRobotProfiles[0];

    return (
      <div className="px-2 mx-3 mt-2">
        <div className="bg-main-color-gradient rounded-3xl px-4 pb-2 pt-2">
          <div className="mx-4 mb-2">
            <span className=" mt-2 font-semibold">
              {/* 高频  */}
              {/* {t("highFrequency", sourceKey.highFrequency)} */}
              {/* <Popover
                content={
                  <div className="flex justify-around">
                    {map(AllExchangePlatforms, (item, index) => {
                      return (
                        <Button
                          className={`mx-2 ${
                            selectedPlatform === item ? "main-button-color" : ""
                          }`}
                          onClick={() => {
                            setSelectedPlatform(item);
                          }}
                        >
                          {t(item, sourceKey.highFrequency)}
                        </Button>
                      );
                    })}
                  </div>
                }
                trigger="click"
                placement="rightTop"
              >
                <Button className="second-button-color main-button-padding">
                  {t("platform", sourceKey.highFrequency)}
                </Button>
              </Popover> */}
            </span>
            <div className="mt-4">
              <div className="flex justify-start">
                <span className={`inline-block w-1/3 `}>
                  {" "}
                  {t("walletLimit", sourceKey.highFrequency)}
                </span>
                <span className="inline-block mx-2">:</span>
                <span className="inline-block w-1/2 ">
                  {isEmpty(selectedRobot)
                    ? 0
                    : formatDecimalNumber(
                        get(selectedRobot, "robotFundLimit.highFrequency.used"),
                        // 10000000,
                        numberDecimal
                      )}{" "}
                  /{" "}
                  {isEmpty(selectedRobot)
                    ? 0
                    : formatDecimalNumber(
                        get(
                          selectedRobot,
                          "robotFundLimit.highFrequency.limit"
                        ),
                        // 10000000,
                        0
                      )}
                </span>
              </div>
              <div className="flex justify-start">
                <span className={`inline-block w-1/3 `}>
                  {" "}
                  {t("totalPosition", sourceKey.highFrequency)}
                </span>
                <span className="inline-block mx-2">:</span>
                <span className="inline-block w-1/2 ">
                  {formatDecimalNumber(stockAmount, 0)}
                </span>
              </div>
            </div>
          </div>

          <div className="border border-b-gray-600"></div>

          <div className="grid grid-cols-4 gap-1 mt-4 ml-6 font-semibold">
            {/* <div className="col-span-4">{robotType}</div> */}
            <div className="col-span-2 flex justify-start">
              <span className={`inline-block w-1/2 `}>
                {" "}
                {t("robot", sourceKey.highFrequency)}
              </span>
              <span className="inline-block mx-2">:</span>
              <span className="inline-block w-1/2 ">
                {arrayLoading ? (
                  <Skeleton.Button active size="small"></Skeleton.Button>
                ) : isEmpty(dataSource) ? (
                  0
                ) : (
                  numRobots
                )}
              </span>
            </div>
            <div className="col-span-2 flex justify-start">
              <span className={`inline-block w-1/2 `}>
                {" "}
                {t("cover", sourceKey.highFrequency)}
              </span>
              <span className="inline-block mx-2">:</span>
              <span className="inline-block w-1/2 ">
                {arrayLoading ? (
                  <Skeleton.Button active size="small"></Skeleton.Button>
                ) : isEmpty(dataSource) ? (
                  0
                ) : (
                  cover
                )}
              </span>
            </div>
            <div className="col-span-2 flex justify-start">
              <span className={`inline-block w-1/2 `}>
                {" "}
                {t("balanceOrder", sourceKey.highFrequency)}
              </span>
              <span className="inline-block mx-2">:</span>
              <span className="inline-block w-1/2 ">
                {arrayLoading ? (
                  <Skeleton.Button active size="small"></Skeleton.Button>
                ) : isEmpty(dataSource) ? (
                  0
                ) : (
                  balance
                )}
              </span>
            </div>

            <div className="col-span-2 flex justify-start">
              <span className={`inline-block w-1/2 `}>
                {" "}
                {t("pnl", sourceKey.highFrequency)}
              </span>
              <span className="inline-block mx-2">:</span>
              <span className="inline-block w-1/2 ">
                {arrayLoading ? (
                  <Skeleton.Button active size="small"></Skeleton.Button>
                ) : isEmpty(dataSource) ? (
                  0
                ) : (
                  formatDecimalNumber(userTotalProfit, numberDecimal) || 0
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const _renderActionsConfirm = () => {
    let selectedRobot = filter(robotProfiles, (item, index) => {
      return get(item, "exchange") === selectedPlatform;
    });
    selectedRobot = selectedRobot[0];

    async function allActions() {
      // console.log(actionType);
      if (actionType === "") {
        return;
      }
      setLoading(true);
      let robotProfileId = map(robotProfiles, "_id");
      allRobotActions({
        robotProfileId: robotProfileId,
        strategy: robotStrategyDataKey.usdt,
        actionType,
        strategy: "highFrequency",
        logs: true
      })
        .then((res) => {
          // console.log(res);

          message.success(t("success"));
          setAllActionVisible(false);
          setActionType("");
          if (actionType === "restart") {
            setActionUpdate2(!actionUpdate2);
          } else {
            setActionUpdate(!actionUpdate);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(err?.message);
          setLoading(false);
        });
    }

    function multiLinkActions() {
      if (multiLinkValue === 1) {
        setLoading(true);
        switchAutoLinkRobots({
          profileId: profileRobot?._id,
          strategy: robotStrategyDataKey.usdt,
          enable: 1,
          clearLinkedRobots: 1,
        })
          .then((res) => {
            // console.log(res);
            message.success(t("success"), 2);
            setAllActionVisible(false);
            setActionUpdate(!actionUpdate);
            // window.location.reload();
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            message.error(err?.message);
            setLoading(false);
          });
      }
    }

    function disableLink() {
      setLoading(true);
      disableLinkedRobots({
        profileId: profile?._id,
        platform: selectedPlatform,
        strategy: robotStrategyDataKey.usdt,
      })
        .then((res) => {
          message.success(t("success"), 2);
          setAllActionVisible(false);
          setActionUpdate(!actionUpdate);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }

    function situationWords(profile) {
      if (isEmpty(profile)) {
        return "-";
      }
      let prestop = get(profile, "settings.highFrequency.preStop");
      // console.log("prestop",prestop)
      switch (prestop) {
        case 0:
          return "";
        case 1:
          return t("tempSell", sourceKey.highFrequency);
        case 2:
          return t("stopBuy", sourceKey.highFrequency);
        default:
          return "";
      }
    }

    let autoLinkDisable =
      multiLinkValue !== 1 && multiLinkValue !== 0 ? true : false;
    let multiLinkDisable =
      multiLinkValue !== 2 && multiLinkValue !== 0 ? true : false;

    let originalAutoLink =
      get(selectedRobot, `settings.${robotStrategyDataKey.usdt}.autoLink`) || 0;

    return (
      <ConfirmationModal
        visible={allActionVisible}
        onClose={() => {
          setAllActionVisible(false);
          setActionUpdate(!actionUpdate);
        }}
        // onOk={() => {}}
        confirmButton={(handleOk) => {}}
      >
        <div className="mt-2 mb-3">
          <React.Fragment>
            {t("chooseActions", sourceKey.highFrequency)}
            <div className="my-3">
              {t("currentSituation", sourceKey.highFrequency)} : <br />
              <span className="font-bold text-green-400">
                {situationWords(profileRobot)}
              </span>
            </div>
            <div className=" pl-5 mt-2">
              <Radio.Group
                name="radiogroup"
                value={actionType}
                onChange={(e) => {
                  setActionType(e.target.value);
                }}
              >
                <Space direction="vertical">
                  <div className="mb-2">
                    {get(profileRobot, "settings.highFrequency.preStop") !==
                      0 &&
                      get(profileRobot, "settings.highFrequency.preStop") && (
                        <Radio value="cancelPreStop" style={{ color: "white" }}>
                          {t("cancelPrestop", sourceKey.highFrequency)}
                        </Radio>
                      )}
                  </div>
                  <div className="mb-2">
                    <Radio
                      value={allRobotsActionsTypes.tempSell}
                      style={{ color: "white" }}
                    >
                      {t("tempSell", sourceKey.highFrequency)}
                    </Radio>
                  </div>
                  <div className="mb-2">
                    <Radio
                      value={allRobotsActionsTypes.stopBuy}
                      style={{ color: "white" }}
                    >
                      {t("stopBuy", sourceKey.highFrequency)}
                    </Radio>
                  </div>
                  <div className="mb-2">
                    <Radio
                      value={allRobotsActionsTypes.restart}
                      style={{ color: "white" }}
                    >
                      {t("restart", sourceKey.highFrequency)}
                    </Radio>
                  </div>
                </Space>
              </Radio.Group>
            </div>
            <div className="flex justify-around flex-wrap border-t-2 py-4">
              <div className=" ">
                <span className="mr-2 ">
                  {t("autoLink", sourceKey.highFrequency)}
                </span>
                <span className="mr-2">
                  <Switch
                    checked={multiLinkValue === 1 ? true : false}
                    onChange={(value) => {
                      if (value === true) {
                        setMultiLinkValue(1);
                      } else {
                        setMultiLinkValue(0);
                      }
                    }}
                    disabled={autoLinkDisable}
                  />
                </span>
                {multiLinkValue === 1 && originalAutoLink === 1 && (
                  <span
                    onClick={() => {
                      setAutoLinkVisible(true);
                      setIsEdit(1);
                    }}
                  >
                    <EyeOutlined
                      style={{
                        color: "rgb(252, 213, 53)",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                )}
              </div>
              <div className="">
                <span className="mr-2 pt-2">
                  {t("multiLinks", sourceKey.highFrequency)}
                </span>
                <span className="mr-2">
                  <Switch
                    checked={multiLinkValue === 2 ? true : false}
                    onChange={(value) => {
                      if (value === true) {
                        setMultiLinkVisible(true);
                        setMultiLinkValue(2);
                      } else {
                        setMultiLinkValue(0);
                      }
                    }}
                    disabled={multiLinkDisable}
                  />
                </span>
                {multiLinkValue === 2 && (
                  <span
                    onClick={() => {
                      setMultiLinkVisible(true);
                      setIsEdit(2);
                    }}
                  >
                    <EyeOutlined
                      style={{
                        color: "rgb(252, 213, 53)",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                )}
              </div>
            </div>
          </React.Fragment>
        </div>

        <div className="flex justify-around ">
          <span
            className="rounded-md main-button-color px-3 py-1.5 cursor-pointer"
            onClick={async () => {
              let currentStatus = convertPrestopToNumber(actionType);
              let currentAutoLinkStatus = get(
                profileRobot,
                "settings.highFrequency.autoLink"
              );

              if (
                get(profileRobot, "settings.highFrequency.preStop") ||
                0 !== currentStatus
              ) {
                await allActions();
              }
              // setLoading(true);
              setTimeout(() => {
                if (multiLinkValue === 0) {
                  disableLink();
                } else if (
                  multiLinkValue === 1 &&
                  currentAutoLinkStatus === 1
                ) {
                  /// skip autolink
                } else {
                  multiLinkActions();
                }
              }, 500);
            }}
          >
            {/* 确认 Confirm */}
            {t("confirm")}
          </span>

          <span
            className=" px-3 py-1.5 second-button-color border rounded-md cursor-pointer"
            onClick={() => {
              setAllActionVisible(false);
              setActionUpdate(!actionUpdate);
            }}
          >
            {t("cancel")}
          </span>
        </div>
      </ConfirmationModal>
    );
  };

  function checkAllPostionsAction(profileData) {
    // console.log(profileData);
    let preStopValue = get(
      profileData,
      `settings.${robotStrategyDataKey.usdt}.preStop`
    );
    let autoLink = get(
      profileData,
      `settings.${robotStrategyDataKey.usdt}.autoLink`
    );
    let manualLink = get(
      profileData,
      `settings.${robotStrategyDataKey.usdt}.manualLink`
    );

    if (preStopValue === 0 && autoLink !== 1 && manualLink !== 1) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {t("usdtITS", sourceKey.highFrequency)}
                </div>
              </React.Fragment>
            }
            onBack={() => {
              navigate(routes.home.to());
            }}
          >
            <div
              className={`relative`}
              // style={{
              //   top: "-200px",
              // }}
            >
              <div className="mx-6">
                <ProfitDetails
                  users={get(props, "user.user")}
                  profile={profile}
                />
              </div>

              <div className="flex justify-between px-5 mt-4">
                <div className="font-bold">
                  {/* 持仓列表  */}
                  {/* {t("openPositionList", sourceKey.highFrequency)} */}
                </div>

                <div className="ml-auto">
                  {map(AllExchangePlatforms, (item, index) => {
                    return (
                      <Button
                        style={{ verticalAlign: "middle" }}
                        className={`mx-2 ${
                          selectedPlatform === item ? "second-button-color" : ""
                        }`}
                        onClick={() => {
                          setSelectedPlatform(item);
                        }}
                      >
                        {/* {t(item, sourceKey.highFrequency)} */}
                        <img
                          src={get(platformLogo, item)}
                          width={20}
                          height={20}
                        />
                      </Button>
                    );
                  })}
                </div>

                <div>
                  <FileProtectOutlined
                    className="black mx-2 pt-1"
                    style={{ fontSize: 23 }}
                    onClick={() => navigate(routes.liquidizedRobotList.to())}
                  />
                </div>
              </div>

              {!isEmpty(displayRobot) ? (
                <>
                  {displayRobot.map((robotType) => {
                    return (
                      <>
                        {_renderTableSummary(robotType)}
                        <div className="mt-1 flex justify-between px-5 py-1">
                          <div
                            className="  text-white"
                            style={{ width: "100%" }}
                          >
                            <Input
                              onChange={searchRobot}
                              placeholder={t("search", sourceKey.pairing)}
                              style={{
                                width: "80%",
                                borderRadius: "12px",
                                boxShadow:
                                  "0px 0px 12px rgba(111, 111, 111, 0.1)",
                              }}
                              allowClear={true}
                              value={searchValue}
                            />
                          </div>

                          <div
                            className="text-white"
                            // style={{ width: "100%" }}
                          >
                            <Button
                              onClick={() => {
                                setAllActionVisible(true);
                                setActionUpdate(!actionUpdate);
                              }}
                              type={`${
                                checkAllPostionsAction(profileRobot) === true
                                  ? "primary"
                                  : ""
                              }`}
                            >
                              {t("allRobotActions", sourceKey.highFrequency)}
                            </Button>
                          </div>
                        </div>
                        {_renderTable(2, robotType)}
                      </>
                    );
                  })}
                </>
              ) : arrayLoading === true ? (
                <>
                  <Skeleton active rows={5}></Skeleton>
                  <Skeleton active rows={5}></Skeleton>
                  <Skeleton active rows={5}></Skeleton>
                </>
              ) : (
                <div className="items-center justify-center mt-10 pb-32">
                  <img
                    src={not_foundIcon}
                    className=" object-contain h-full w-full"
                    style={{ height: "15vh" }}
                  />

                  <br></br>
                  <div className="flex justify-center items-center">
                    <div
                      className="flex justify-center items-center font-black text-base text-center mb-2 text-gray-400"
                      style={{ width: 310 }}
                    >
                      {/* 您暂时还没创建任何机器人。 */}
                      {/* <br /> */}
                      {t("noRobot", sourceKey.highFrequency)}
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <Button
                      block
                      className="main-button-color text-xl my-3 "
                      style={{ width: 250, borderRadius: 25, height: 65 }}
                      onClick={() => navigate(routes.robot.to())}
                      size="large"
                    >
                      {/* 创建机器人 <br /> */}
                      {/* Create Robot */}
                      {t("createRobot", sourceKey.highFrequency)}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </NavHeader>

          {_renderActionsConfirm()}
          <MultiLinkRobotPage
            edit={isEdit}
            visible={multiLinkVisible}
            onClose={() => {
              setMultiLinkVisible(false);
              if (isEdit !== 0) {
                setIsEdit(0);
              }
              setActionUpdate(!actionUpdate);
            }}
            onSuccess={() => {
              setMultiLinkVisible(false);
              setAllActionVisible(false);
              setActionUpdate(!actionUpdate);
            }}
            platform={selectedPlatform}
            profile={profile}
            // edit={true}
          />

          <AutoLinkRobotPage
            edit={isEdit}
            visible={autoLinkVisible}
            onClose={() => {
              setAutoLinkVisible(false);
              if (isEdit !== 0) {
                setIsEdit(0);
              }
              setActionUpdate(!actionUpdate);
            }}
            platform={selectedPlatform}
            profile={profile}
            // edit={true}
          />
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
  updatePreviousPagination,

  // updateRobotData
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
