import { Skeleton } from "antd";
import { get, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import io from "socket.io-client";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { socketUrl } from "../../../utilities/startUp";

// markup
const SymbolMinPrice = (props) => {
  // const location = useLocation();
  //   console.log(price);
  const [priceValue, setPriceValue] = useState({});
  const { symbol, coinStructure, record } = props;
  // console.log(symbol);
  // console.log(coinStructure);

  useEffect(() => {
    if (!isEmpty(symbol)) {
      const socket = io(socketUrl, {
        origins: socketUrl,
        query: {
          symbols: [symbol],
        },
      });

      socket.on("connect", () => {
        // console.log("Connect to socket");
      });

      socket.on("common", (data) => {
        let priceData = get(data, symbol);
        setPriceValue(priceData || {});

        if (props?.onSetPrice) {
          props.onSetPrice(priceData);
        }
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [symbol]);

  const _renderPrice = useMemo(() => {
    return (
      <span>
        {isEmpty(priceValue) ? (
          <Skeleton.Button />
        ) : (
          <>
            <div className="pl-1 py-1">
              <div
                className={`flex justify-start ${
                  get(priceValue, "price") >= get(record, "minPrice")
                    ? record?.customLiquidation === 1
                      ? ""
                      : "text-green-500"
                    : "text-red-500"
                }`}
                style={{ fontSize: "11px" }}
              >
                {formatDecimalNumber(
                  get(priceValue, "price"),
                  get(coinStructure, `${record?.symbol}.price`) || 4
                )}
              </div>
              <div
                className="flex justify-start"
                // style={{ fontSize: "0.55rem" }}
                style={{ fontSize: "11px" }}
              >
                {formatDecimalNumber(
                  get(record, "minPrice"),
                  get(coinStructure, `${record?.symbol}.price`) || 4
                )}
              </div>
            </div>
          </>
        )}
      </span>
    );
  }, [priceValue]);

  return <React.Fragment>{_renderPrice}</React.Fragment>;
};

export default SymbolMinPrice;
